




@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap');

*{
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a{
  text-decoration: none;
}




.thank-you-container {
  width: 100%;
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.thank-you-container p{
  margin: 20px;
}

.thank-you-button {
  padding: 10px 20px;
  border: none;
  background-color: #000000;
  color: white;
  cursor: pointer;
  margin: 10px 50px 10px 50px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .thank-you-container {
      margin: 20px 10px;
      padding: 10px;
  }
}



/* Header */


.desktop-view-logo{
  padding:0px;
}


.mobile-view{
  display:none;
}

nav{
  background-color: rgb(3 3 3);
  height: 125px;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
}


.leftHeader, .rightHeader{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.leftHeader{
  width: 40%;
  justify-content: space-around;
}

.rightHeader{
  width: 40%;
  justify-content: space-around;
}

nav h3{
  display: flex;
  align-items: center;
}


nav a{
  color:white;
  text-decoration: none;
  padding: 50px;
  width: fit-content;
  display: flex;
  justify-content: center;
}

nav a:hover{
  color:rgb(203, 203, 203);
  scale: 1.1;
  transition-duration: 0.25s;
}


nav img{
  margin: 0px 50px;
}


/* Home Page */

.hero-section{
  background-image: url("https://assets.architecturaldesigns.com/plan_assets/338729105/large/911023JVD_render_001_1664829604.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 85vh;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items:flex-start
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1;
}

.hero-section .main h2,h5,a{
  position: relative;
  z-index: 2;
  color: white;
  padding: 25px 25px 25px 0px;
  text-align: start;
}


.hero-section .main{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top:250px;
  margin-left: 25px;
}


.hero-section .main h2{
  font-size: 60px;
  padding-bottom: 0px;
  margin-right: 25%;
}

.hero-section .main h5{
  font-size: 20px;
  font-weight: 100;
}

.hero-section .main a{
  width: 150px;
  height: 15px;
  font-size: 15px;
  background-color: white;
  color:black;
  border-radius: 7.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  padding: 20px 20px 20px 20px;
}


/* ---- */

.suited-for-list{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
}

.suited-for-list .residencial, .suited-for-list .commercial {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  box-shadow: #00000024 0px 0px 15px 5px;
}

.suited-for-list .residencial img, .suited-for-list .commercial img {
  width: 100%;
  /* max-width: 150px; */
  object-fit: contain;
}

.suited-for-list .residencial img{
  border-radius: 7.5px 0px 0px 7.5px;
}

.suited-for-list .commercial img{
  border-radius: 0px 7.5px 7.5px 0px;
}


.suited-for-list .residencial {
  border-radius: 7.5px;
}


.suited-for-list .commercial {
  border-radius: 7.5px;
}

.suited-for-list .residencial .residencial-details, .suited-for-list .commercial .commercial-details {
  display: flex;
  flex-direction: column;
}

.suited-for-list .residencial .residencial-details h3, .suited-for-list .commercial .commercial-details h3 {
  font-size: 2em;
  padding: 20px 0;
}

.suited-for-list .residencial .residencial-details ul, .suited-for-list .commercial .commercial-details ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 1.1em;
}

.suited-for-list .residencial .residencial-details ul li, .suited-for-list .commercial .commercial-details ul li{
  margin-bottom: 7px;
}


.suited-for-list .residencial .residencial-details{
  align-items: center;
  border: solid 1px black;
  border-radius: 3px;
  padding: 50px;
}

.suited-for-list .commercial .commercial-details{
  justify-content: center;
  align-items: center;
  padding: 50px;
  border: solid 1px black;
  border-radius: 3px;
}





/* --------- */

.services {
  width: 100%;
  background-color: rgb(3 3 3);
  height: max-content;
  padding: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.services h2 {
  text-align: center;
  padding: 20px 0;
  color: white;
  font-size: 35px;
  margin-bottom: 25px;
}

.service-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.service-item {
  position: relative;
  background-size: cover;
  background-position: center;
  width: 50%;
  height: 17em;
  margin: 20px 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: 7.5px;
  background-color: rgba(255, 255, 255, 0.187);
}

.service-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.288);
  border-radius: 7.5px;
}
.service-item img{
  width: 55px;
  height: 55px;
}

.service-item h3 {
  font-size: 20px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 2;
  margin-top:15px;
}

/* Adjust the layout when the viewport is wider than 600px */
@media (min-width: 600px) {
  .service-item {
    width: 45%;
    margin: 20px 2.5%;
  }
}

/* Adjust the layout when the viewport is wider than 900px */
@media (min-width: 900px) {
  .service-item {
    width: 29%;
    margin: 20px 1.5%;
  }
}





/* ----  */


.contact-me {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vh;
  padding: 15px;
  animation: appear 1s ease-in-out;
}

.contact-me h1{
  margin: 35px;
}

.contact-container{
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: center;
  height: 100%;
}

.contact-me h3 {
  margin-bottom: 20px;
  font-size: 2em;
  color: #333;
}

.contact-me form {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0,0,0,0.1); /* Shadow added */
  border-radius: 7.5px 0px 0px 7.5px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgb(3 3 3);
  color: white;
}

.contact-me form input, .contact-me form textarea {
  margin-bottom: 25px;
  padding: 15px 5px 15px 5px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 15px 4px rgba(0,0,0,0.06);
  width: 75%;
}


.contact-me form :nth-child(5) {
  height: 100px;
  resize: none;
}

.contact-me form :nth-child(5)::placeholder{
  position: absolute;
  top: 10px;
  left: 10px;
  color: #888;
  pointer-events: none;
}


.contact-me-prompt{
  height: 85%;
  width: 50%;
}

.contact-info{
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.125);
  height: 85%;;
  width: 50%;
  border-radius: 0px 7.5px 7.5px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: start;
  padding-left: 125px;
}


.contact-info-title{
  font-size: 35px;
  margin-bottom: 10px;
}
.contact-info-items{
  display: flex;
  align-items: center;
  margin: 25px 25px 25px 0px;
}

.contact-info-items img{
  width: 35px;
  margin-right: 15px;
}


.contact-name{
  display: flex;
  width:75%;
  margin-top: 20px;
}

.contact-name input{
  width: 40%;
}

.contact-name input:first-child{
  margin-right: 15px;
}

.contact-button{
  border: solid white 1px;
  padding: 10px;
  width:75%;
  border-radius: 7.5px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

.contact-button:hover{
  color:black;
  background-color: white;
}

.contact-button:active{
  scale: 0.95;
}

.follow-us h2{
  font-size: 27px;
  font-weight: 400;
}

.social-icons{
  margin:15px 0px;
}
.social-icons a{
  padding: 25px 25px 25px 0px;
}

/* Animation */
@keyframes appear {
  0% {transform: translateY(-50px); opacity: 0;}
  100% {transform: translateY(0px); opacity: 1;}
}




/* Footer */

.footer{
  background-color: rgb(3 3 3);
  color:white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer .footerLogo{
  width: 800px;
  height: 450px;
}


.footerNav {
  border:solid 1px white;
  width: 100%;
  height: fit-content;
}

.footerNav a{
  padding: 25px 70px;
  align-items: center;
  text-decoration: underline;
}
.footer p{
  margin: 15px 0px;
}


/* Pricing */

.pricing{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
}

.pricing ul{
  text-align: start;
  margin: 25px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding:0px 50px;
}


.pricing h2{
  margin: 25px;
}

.residential-pricing-button{
  color:black;
  border-radius: 7.5px;
}

.commercial-pricing-button{
  color:black;
  border-radius: 7.5px;
}


.switch-button{
  border:solid 1px black;
  border-radius: 7.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch-button a{
  padding: 15px 50px;
}


.residencial-pricing-button{
  border-radius: 7.5px;
}

.active{
  background-color: rgb(0, 0, 0);
  color:white;
}

.in-active{
  background-color: rgb(255, 255, 255);
  color: black;
  border-radius: 7.5px;
}

.commercial-pricing-button{
  border-radius: 7.5px;
}




.card{
  box-shadow: #00000024 0px 0px 15px 5px;
  border-radius: 7.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin: 10px;
}

.card .residencial-button{
  border:solid 1px black;
  text-align: center;
  border-radius: 7.5px;
  margin-top: 25px;
  color:black;
  padding: 5px 0px;
}

.card .residencial-button:hover{
  background-color: black;
  color: white;
}



.card p{
  border-bottom: 1px solid rgb(0, 0, 0);
  font-size: 20px;
}

.card img{
  width: 100px;
  margin: 20px;
}

.residencialPricing{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 25px;
}





@media screen and (min-width: 350px) and (max-width: 650px) {
  .desktop-view{
    display:none;
  }

  .mobile-view{
    display: flex;
    flex-direction: column;
    background-color: black;
    position: relative;
    z-index: 3;
  }

  .mobile-view a{
    border: solid white 1px;
    border-left: none;
    border-right: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-view img{
    width: 150px;
    align-self: center;
  }

  .mobile-view .nav-toggle{
    position: absolute;
    width: 50px;
    right: 15px;
    top: 35px;
    z-index: 5;
  }
  .nav-list{
    display: none;
  }

  .hero-section .main {
    margin-top: 125px;
  }

  .footer .footerLogo{
     width:50px;
  }
  .contact-container{
    display: flex;
    flex-direction: column-reverse;
    width:95%;
  }
  .contact-info{
    padding-left: 0px;
  }

  .contact-me form{
    height: 550px;
  }


  .hero-section .main h2{
    font-size: 27px;
    margin-right: 0px;
    max-width: 450px;
  }
  .hero-section .main h5{
    font-size: 16px;
  }

  .suited-for-list .residencial .residencial-details, .suited-for-list .commercial .commercial-details{
    padding: 15px;
    border:0px;
  }

  .suited-for-list .commercial{
    flex-direction: column-reverse;
  }


  .suited-for-list .residencial .residencial-details h3, .suited-for-list .commercial .commercial-details h3{
    font-size: 1.4em;
  }
  .suited-for-list .residencial img, .suited-for-list .commercial img{
    max-width: 100%;
  }
  .suited-for-list .residencial .residencial-details ul, .suited-for-list .commercial .commercial-details ul{
    list-style-type: disc;
  }

  .services{
    padding:0px 0px 50px 0px;
  }

  .service-item{
    width: 70%;
  }

  .contact-me{
    height: auto;
    padding: 0px 0px 50px 0px;
  }

  .contact-info{
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .contact-name{
    width: 85%;
  }
  .contact-me form input, .contact-me form textarea {
    width: 85%;
  }

  .contact-button{
    width: 85%;
    cursor:pointer;
  }
  .contact-button:active{
    scale: 0.95;
  }
  .contact-info-items {
    margin: 25px 0px 25px 0px;
    flex-direction: column;
  }

  .contact-info-items img{
    margin: 15px 0px;
  }

  .contact-me-prompt{
    width: 100%;
  }

  .follow-us{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .social-icons{
    width: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .social-icons a{
    padding: 0;
  }

  .footer .footerLogo{
    width: 100%;
    height: auto;
  }

  .footer p{
    font-size: 10px;
    font-weight: bolder;
  }
  .footerNav {
    flex-direction: column;
    align-items: center;
  }
  
  .footerNav a{
    text-decoration: none;
    border-bottom: 1px solid white;
    border-left: none;
    border-right: none;
    width: 100%;
  }
  .footer p{
    margin: 15px 0px;
  }

  .follow-us{
    margin-top: 25px;
  }
  
  .switch-button a{
    padding: 15px 35px;
  }

  .pricing{
    padding: 25px;
  }

  .pricing .commercial-pricing-container{
    height: auto;
    width: auto;
  }
  .pricing .commercial-pricing{
    flex-direction: column;
  }

  .pricing .commercial-pricing .pricing-hook{
    width: 80%;
  } 

  .pricing .commercial-pricing .pricing-hook ul{
    padding:0px;
  }
  .pricing .commercial-pricing .pricing-hook p{
    margin-bottom: 25px ;
  }
  .pricing p{
    margin:10px;
  }
}






@media screen and (min-width: 650px) and (max-width: 850px) {
  .desktop-view{
    display:none;
  }

  .mobile-view{
    display: flex;
    flex-direction: column;
    background-color: black;
    position: relative;
    z-index: 3;
  }

  .mobile-view a{
    border: solid white 1px;
    border-left: none;
    border-right: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-view img{
    width: 150px;
    align-self: center;
  }

  .mobile-view .nav-toggle{
    position: absolute;
    width: 50px;
    right: 15px;
    top: 35px;
  }
  .nav-list{
    display: none;
  }

  .hero-section .main {
    margin-top: 125px;
  }
  .footer .footerLogo{
     width:50px;
  }
  .contact-container{
    display: flex;
    flex-direction: column-reverse;
    width:95%;
  }
  .contact-info{
    padding-left: 0px;
  }

  .contact-me form{
    height: 550px;
  }


  .hero-section .main h2{
    font-size: 30px;
    margin-right: 0px;
    max-width: 600px;
  }
  .hero-section .main h5{
    font-size: 17px;
    max-width: 450px;
  }

  .suited-for-list .residencial .residencial-details, .suited-for-list .commercial .commercial-details{
    padding: 15px;
    border: solid 1px black;
    margin: 25px;
    width: 80%;
  }

  .suited-for-list .commercial{
    flex-direction: column-reverse;
  }


  .suited-for-list .residencial .residencial-details h3, .suited-for-list .commercial .commercial-details h3{
    font-size: 1.4em;
  }
  .suited-for-list .residencial img, .suited-for-list .commercial img{
    max-width: 100%;
  }
  .suited-for-list .residencial .residencial-details ul, .suited-for-list .commercial .commercial-details ul{
    list-style-type: disc;
  }

  .services{
    padding:0px 0px 50px 0px;
  }

  .service-item{
    width: 40%;
  }

  .contact-me{
    height: auto;
    padding: 0px 0px 50px 0px;
  }

  .contact-info{
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .contact-name{
    width: 85%;
  }
  .contact-me form input, .contact-me form textarea {
    width: 85%;
  }

  .contact-button{
    width: 85%;
    cursor:pointer;
  }
  .contact-button:active{
    scale: 0.95;
  }

  .contact-info-items {
    margin: 25px 0px 25px 0px;
    flex-direction: column;
  }

  .contact-info-items img{
    margin: 15px 0px;
  }

  .contact-me-prompt{
    width: 100%;
  }

  .follow-us{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .social-icons{
    width: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .social-icons a{
    padding: 0;
  }

  .footer .footerLogo{
    width: 100%;
    height: auto;
  }

  .footer p{
    font-size: 12px;
    font-weight: bolder;
  }
  .footerNav {
    flex-direction: column;
    align-items: center;
  }
  
  .footerNav a{
    text-decoration: none;
    border-bottom: 1px solid white;
    border-left: none;
    border-right: none;
    width: 100%;
  }
  .footer p{
    margin: 15px 0px;
  }

  .follow-us{
    margin-top: 25px;
  }
  
  .pricing{
    padding: 25px;
  }

  .pricing .commercial-pricing-container{
    height: auto;
    width: 75%;
  }
  .pricing .commercial-pricing{
    flex-direction: column;
  }

  .pricing .commercial-pricing .pricing-hook{
    width: 80%;
  } 

  .pricing .commercial-pricing .pricing-hook ul{
    padding:0px;
  }
  .pricing .commercial-pricing .pricing-hook p{
    margin-bottom: 25px ;
  }
  .pricing p{
    margin:10px;
  }

}



@media screen and (min-width: 850px) and (max-width: 1050px) {
  .desktop-view{
    display:flex;
  }

  .mobile-view{
    display: none;
  }
  .nav a{
    padding:30px;
  }

  .mobile-view a{
    border: solid white 1px;
    border-left: none;
    border-right: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-view img{
    width: 150px;
    align-self: center;
  }

  .mobile-view .nav-toggle{
    position: absolute;
    width: 50px;
    right: 15px;
    top: 35px;
  }
  .nav-list{
    display: none;
  }

  .hero-section .main {
    margin-top: 125px;
  }
  .footer .footerLogo{
     width:50px;
  }
  .contact-container{
    display: flex;
    flex-direction: column-reverse;
    width:95%;
  }
  .contact-info{
    padding-left: 0px;
  }

  .contact-me form{
    height: 550px;
  }


  .hero-section .main h2{
    font-size: 45px;
    margin-right: 0px;
    max-width: 800px;
  }
  .hero-section .main h5{
    font-size: 20px;
    max-width: 500px;
  }

  .suited-for-list .residencial .residencial-details, .suited-for-list .commercial .commercial-details{
    padding: 15px;
    border: solid 1px black;
    margin: 25px;
    width: 80%;
  }

  .suited-for-list .commercial{
    flex-direction: column;
  }


  .suited-for-list .residencial .residencial-details h3, .suited-for-list .commercial .commercial-details h3{
    font-size: 1.4em;
  }
  .suited-for-list .residencial img, .suited-for-list .commercial img{
    max-width: 50%;
  }
  .suited-for-list .residencial .residencial-details ul, .suited-for-list .commercial .commercial-details ul{
    list-style-type: disc;
  }

  .suited-for-list .residencial, .suited-for-list .commercial{
    flex-direction: row;
    height: 350px;
  }


  .services{
    padding:0px 0px 50px 0px;
  }

  .service-item{
    width: 40%;
  }

  .contact-me{
    height: auto;
    padding: 0px 0px 50px 0px;
  }

  .contact-info{
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .contact-name{
    width: 85%;
  }
  .contact-me form input, .contact-me form textarea {
    width: 85%;
  }

  .contact-button{
    width: 85%;
    cursor:pointer;
  }
  .contact-button:active{
    scale: 0.95;
  }

  .contact-info-items {
    margin: 25px 0px 25px 0px;
    flex-direction: column;
  }

  .contact-info-items img{
    margin: 15px 0px;
  }

  .contact-me-prompt{
    width: 100%;
  }

  .follow-us{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .social-icons{
    width: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .social-icons a{
    padding: 0;
  }

  .footer .footerLogo{
    width: 100%;
    height: auto;
  }

  .footer p{
    font-size: 12px;
    font-weight: bolder;
  }
  .footerNav {
    flex-direction: column;
    align-items: center;
  }
  
  .footerNav a{
    text-decoration: none;
    border-bottom: 1px solid white;
    border-left: none;
    border-right: none;
    width: 100%;
  }
  .footer p{
    margin: 15px 0px;
  }

  .follow-us{
    margin-top: 25px;
  }

  .pricing{
    padding: 25px;
  }

  .pricing .commercial-pricing-container{
    height: auto;
    width: 50%;
  }
  .pricing .commercial-pricing{
    flex-direction: column;
  }

  .pricing .commercial-pricing .pricing-hook{
    width: 80%;
  } 

  .pricing .commercial-pricing .pricing-hook ul{
    padding:0px;
  }
  .pricing .commercial-pricing .pricing-hook p{
    margin-bottom: 25px ;
  }
  .pricing p{
    margin:10px;
  }

  

}


@media screen and (min-width: 1050px){
  .hero-section .main{
    margin-top:125px;
  }
  .hero-section .main h2{
    font-size: 50px;
    margin-right: 0px;
    max-width: 900px;
  }
  .hero-section .main h5{
    font-size: 20px;
    max-width: 500px;
  }
  .suited-for-list .residencial img, .suited-for-list .commercial img{
    width: 50%;
  }
  .suited-for-list .commercial .commercial-details {
    padding: 0px;
  }
  .suited-for-list .residencial .residencial-details, .suited-for-list .commercial .commercial-details{
    border:0px;
    padding: 60px 50px;
    width: 50%;
  }
  .suited-for-list .residencial, .suited-for-list .commercial{
    flex-direction: row;
  }
  .suited-for-list .residencial .residencial-details ul, .suited-for-list .commercial .commercial-details ul{
    list-style-type: disc;
  }
  .suited-for-list .residencial .residencial-details {
    padding:25px 50px;
  }
  
}


.commercial-pricing-container{
  text-align: center;
  margin: 50px 0px;
  height: 600px;
  width: 1000px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 7.5px;
  overflow: hidden;
  box-shadow: rgba(80, 80, 80, 0.405) 0px 0px 25px 10px;
}


.commercial-pricing{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
}



/* .commercial-pricing form{
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 15px rgba(0,0,0,0.1);
  border-radius: 7.5px 0px 0px 7.5px;
  height: 100%;
  width: 25%;
  justify-content: center;
  align-items: center;
  background-color: rgb(3 3 3);
  color: white;
} */

/* .commercial-pricing form input{
  margin-bottom: 25px;
    padding: 15px 5px 15px 5px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 15px 4px rgba(0,0,0,0.06);
    width: 75%;

}

.commercial-pricing ul{
  padding :0px;
  text-align: start;
}
 */


.responsive-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.responsive-form label {
  margin-bottom: 10px;
  color: #333; /* Adjust this to fit your color scheme */
}

.responsive-form input,
.responsive-form textarea {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc; /* Adjust this to fit your color scheme */
  border-radius: 4px; /* Adjust this to fit your design */
}

.responsive-form input[type="submit"] {
  background: #000000; /* Adjust this to fit your color scheme */
  color: #fff; /* Adjust this to fit your color scheme */
  cursor: pointer;
}

#commercial-submit-button:active{
   scale: 0.95;
}
.responsive-form textarea{
  resize: none;
}

 
.pricing-hook{
  margin-right: 15px;
  width: 50%;
}